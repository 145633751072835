export default [
  {
    title: 'Generar Comprobante',
    route: 'facturador',
    icon: 'FileIcon',
  },
  {
    title: 'Reporte Comprobantes',
    route: 'reporte',
    icon: 'FileTextIcon',
  },
]
